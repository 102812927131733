import React, { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import SeanceItem from 'app/containers/SeanceItemContainer';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import { formatMoment, setYaReachGoal } from 'app/selectors/Helpers';
import {
	getBusinessDate, isMobileSelector, getCinema,
	getIsSeveralCinemasInCity, getRoutePrefix, getSelectedDay,
	getWithNewDesign,
	getIsKinokassa,
} from 'app/selectors/AppSelectors';
import ReleasePoster from 'app/components/release_item/ReleasePoster';
import * as style from 'css/release-item.module.scss';
import ReleaseQualifiersWrapper from 'app/components/ReleaseQualifiersWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDay } from 'app/actions/AppActions';
import { IRelease } from 'app/types/releases';
import { DailySeance } from 'app/types/common';

interface IProps {
	release: IRelease,
	soon?: boolean,
	today?: boolean,
	page?: string | null,
	isImax?: boolean,
	isVip?: boolean,
}

function ReleaseItemComponent({
	release,
	soon = false,
	today = false,
	page = null,
	isImax = false,
	isVip = false
}: IProps) {
	const dispatch = useDispatch();

	const isMobile = useSelector(isMobileSelector);
	const currentCityTitle = useSelector(getCinema).city;
	const isSeveralCinemasInCity = useSelector(getIsSeveralCinemasInCity);
	const prefix = useSelector(getRoutePrefix);
	const selectedDay = useSelector(getSelectedDay);

	const {
		id: releaseId,
		rambler_id: ramblerReleaseId,
		cinema_next_date: cinemaNextDate,
		repertoire_start_date: startDate,
		seances,
		poster,
		thumbnail,
		title,
		all_qualifiers,
	} = release;
	const withNewDesign = useSelector(getWithNewDesign);
	const isKinokassa = useSelector(getIsKinokassa);
	const backgroundPoster = isMobile || soon ? thumbnail || poster : poster || thumbnail;
	const soonStartDate = cinemaNextDate || startDate;
	const momentCinemaNextDate = soon ? moment(soonStartDate) : moment(cinemaNextDate);

	const handleReleaseClick = useCallback(() => {
		if (today) {
			dispatch(updateSelectedDay(moment()));
		}

		setYaReachGoal('open-release', {
			section: page,
			movie: title,
			release_id: releaseId,
			city: currentCityTitle,
		});
	}, [today, page, currentCityTitle, releaseId, title])

	const handleButtonClick = () => {
		dispatch(updateSelectedDay(moment(cinemaNextDate)));
	}

	const renderBadges = () => {
		const {age_rating: ageRating, genres} = release;

		return (
			<div className="releases-item-description__badge text text--size-12">
				{ageRating ? <span>{ageRating}</span> : null}
				{genres.length ? genres.map(({title}, index) => <span key={index}>{title}</span>) : null}
			</div>
		);
	}

	const renderSeance = (seance: DailySeance, releaseId: number, ramblerReleaseId: number) => {
		return !withNewDesign || isKinokassa
			? (
				<SeanceItem.Main
					seance={seance}
					releaseId={releaseId}
					ramblerReleaseId={ramblerReleaseId}
					key={seance.id}
					page="release"
				/>
			) : (
				<SeanceItem.withNewDesign
					seance={seance}
					releaseId={releaseId}
					ramblerReleaseId={ramblerReleaseId}
					key={seance.id}
					page="release"
				/>
			)
	}

	const renderButtonShowOtherSeances = useMemo(() => (
		<button
			className="seance-item time-tooltip"
			onClick={handleButtonClick}
		>
			{`${i18n.t('Seances')} ${i18n.t('on')} ${getBusinessDate(momentCinemaNextDate).calendar()}`}
		</button>
	), [momentCinemaNextDate]);

	return (
		<KeydownEnterLink
			to={`${prefix}/release/${releaseId}${isImax ? '/imax' : ''}${isVip ? '/vip' : ''}${
				selectedDay ? `?date=${formatMoment(selectedDay)}` : ''
			}`}
			className={cn(`releases-item ${style.releaseItem}`, {'releases-item_soon': soon})}
			onClick={handleReleaseClick}
		>
			<>
				<ReleasePoster
					poster={backgroundPoster}
					qualifiers={all_qualifiers}
					isAfisha={true}
					isShowOnSale={!!cinemaNextDate && soon}
					isSoon={soon}
				/>
				{soon ?
					<div className="releases-item__info">
						<div className="releases-item-description">
							{title && <div
								className="releases-item-description__title releases-item-description__title_small"
							>
								{title}
							</div>}
							<ReleaseQualifiersWrapper
								classes="releases-item__badge text text--size-12 releases-item__badge--desktop"
								qualifiers={all_qualifiers}
							/>
							{!withNewDesign ? renderBadges() : null}
						</div>
						<div className="releases-item__date-wrapper">
							<span className="releases-item__date">
								{i18n.t('from')} {momentCinemaNextDate.format('D MMMM YYYY')}
							</span>
						</div>
					</div>
				:
					<div className="releases-item__info">
						<div className="releases-item-description">
							{title && <div className="releases-item-description__title text text--size-24">{title}</div>}
							<ReleaseQualifiersWrapper
								classes="releases-item__badge releases-item__badge--mobile text text--size-12"
								qualifiers={all_qualifiers}
								withProCultureBadge
							/>
							{renderBadges()}
						</div>
						{!withNewDesign && !isSeveralCinemasInCity &&
							<div className="releases-item-schedule">
								{seances.length ?
									seances.map(seance =>
										renderSeance(seance, releaseId, ramblerReleaseId)
									)
								: renderButtonShowOtherSeances}
							</div>}
					</div>
				}
				{withNewDesign && !isSeveralCinemasInCity && !soon &&
					<div className="releases-item-schedule">
						{seances.length ?
							seances.map(seance =>
								renderSeance(seance, releaseId, ramblerReleaseId)
							)
						: renderButtonShowOtherSeances}
					</div>}
			</>
		</KeydownEnterLink>
	);
}

export default memo(ReleaseItemComponent)
